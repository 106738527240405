export interface dataStatic {
    teamSummary: any;
    genericActivity: any;
    resourceDetail: any;
    dataResource: any;
}


/*  Mock static data for time report */
export const DATASTATIC: dataStatic = {
    teamSummary: [],
    genericActivity: [],
    resourceDetail: [],
    dataResource: [],
};

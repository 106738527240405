import { Injectable } from '@angular/core';
import { TaskService, TaskModel } from './tasks.service';
import { ProjectModel } from './projects.service'
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class ExportService extends TaskService {

  constructor(http: HttpClient) { super(http); }

  public getTasksFiltered(
    filters,
    headers?: HttpHeaders) {
    const params = { filter: filters };
    const url: string = this.buildUrl(TaskModel, null, null, null, null);
    const requestOptions: object = this.buildRequestOptions({ headers, params, observe: 'response' });
    // console.log('url', url);
    // console.log('requestOptions', requestOptions);

    return this.http.get(url, requestOptions)
      .pipe(
        map((res: any) => {
          return this.extractCollectionData(res, TaskModel);
        }),
        catchError((res: any) => this.handleError(res))
      );
  }

  public getReport(
    project: string,
    template: string,
    tasks: string,
    headers?: HttpHeaders
  ) {
    // TODO Generalizzare per i vari report ( con diversi parametri ).

    let body: any = {
      'template': template
    };
    if (tasks.length > 0) {
      body = {
        'template': template,
        'tasks': tasks
      };
    }

    // console.log('body', body);
    const url: string = this.buildUrl(ProjectModel, null, project, null, null) + '/generate-report';
    // console.log('url', url);
    const requestOptions: object = this.buildRequestOptions({ headers, observe: 'response' });
    // const requestOptions: object = this.buildRequestOptions({ headers, responseType: 'blob', observe: 'response' });
    // console.log('requestOptions', requestOptions);

    return this.http.post(url, body, requestOptions)
      .pipe(
        map((res: HttpResponse<object>) => {
          // console.log('res', res);
          return res;
          // return res.body;
        }),
        catchError((res: any) => this.handleError(res))
      );
  }

  public getReportQueued(
    project: string,
    template: string,
    tasks: string,
    headers?: HttpHeaders
  ) {
    let body: any = {
      'template': template
    };
    if (tasks.length > 0) {
      body = {
        'template': template,
        'tasks': tasks
      };
    }
    const url: string = this.buildUrl(ProjectModel, null, project, null, null) + '/generate-report-queued';
    const requestOptions: object = this.buildRequestOptions({ headers, observe: 'response' });
    return this.http.post(url, body, requestOptions)
      .pipe(
        map((res: HttpResponse<object>) => {
          return res;
        }),
        catchError((res: any) => this.handleError(res))
      );
  }

  public getReportUrl(
    project: string,
    template: string,
    tasks: string) {
    let params = {
      template: template
    };
    if (tasks.length > 0) {
      params[tasks] = tasks
    }
    const searchParams = new URLSearchParams(params);
    const url: string = this.buildUrl(ProjectModel, null, project, null, null) + '/generate-report?' + searchParams.toString();

    return encodeURI(url);
  }

  public getCSV(
    project: string,
    filename: string,
    tasks: string,
    csvType: string = 'base',
    headers?: HttpHeaders
  ) {
    let body: any = {
      'filename': filename,
      'csv_type': csvType
    };
    if (tasks.length > 0) {
      body['tasks'] = tasks;
    };

    // console.log('body', body);
    const url: string = this.buildUrl(ProjectModel, null, project, null, null) + '/download-csv';
    // console.log('url', url);
    // const requestOptions: object = this.buildRequestOptions({ headers, observe: 'response' });
    // const requestOptions: object = this.buildRequestOptions({ headers, responseType: 'blob', observe: 'response' });
    const requestOptions: object = this.buildRequestOptions({ headers, responseType: 'text', observe: 'response' });
    // console.log('requestOptions', requestOptions);

    return this.http.post(url, body, requestOptions)
      .pipe(
        map((res: HttpResponse<object>) => {
          // console.log('res', res);
          return res;
          // return res.body;
        }),
        catchError((res: any) => this.handleError(res))
      );
  }

}

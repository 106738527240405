import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import { JsonapiService, JsonApiConfig, JsonApiModelConfig, JsonApiModel, Attribute } from './jsonapi.service';
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';

@JsonApiModelConfig({
  type: 'users'
})
export class OwnerModel extends JsonApiModel {
  @Attribute()
  name: string;

  @Attribute()
  surname: string;

  @Attribute()
  email: string;

  @Attribute()
  in_house: number;

  @Attribute()
  disable_login: number;

  @Attribute()
  'created-at': Date;

  @Attribute()
  'updated-at': Date;
}

const config: JsonApiConfig = {
  baseUrl: environment.api.base_url.replace(/\/+$/, ""),
  apiVersion: environment.api.version,
  models: {
    users: OwnerModel
  }
}

@Injectable({
  providedIn: 'root'
})
@JsonApiConfig(config)
export class OwnerService extends JsonapiService {
  constructor(
    http: HttpClient
  ) {
    super(http);
  }

  public _getOwners() {
    return this.getCollection(OwnerModel);
  }


  public getOwners(
    headers?: HttpHeaders
  ) {
    const baseUrl = config.baseUrl || this.JsonApiConfig.baseUrl;
    const apiVersion = config.apiVersion || this.JsonApiConfig.apiVersion;
    const customUrl: string = [baseUrl, apiVersion, 'users/boat-owners'].filter((x) => x).join('/');
    const url: string = this.buildUrl(OwnerModel, null, null, null, customUrl);
    const params = {};
    const requestOptions: object = this.buildRequestOptions({ headers, params });

    return this.http.get(url, requestOptions)
      .pipe(
        map((res: HttpResponse<object>) => {
          // console.log('HttpResponse', res);
          let data = this.extractCollectionData(res, OwnerModel);
          return data;
        }),
        catchError((res: any) => this.handleError(res))
      );
  }
}
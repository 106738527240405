import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams, AlertController } from '@ionic/angular';
import { OwnerService, OwnerModel } from 'src/app/core/services/owners.service';
import * as uuid from 'uuid';
import { HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.page.html',
  styleUrls: ['./add-user.page.scss'],
})
export class AddUserPage implements OnInit {
  public owner = {
    name: '',
    surname: '',
    email: '',
    password: '',
    in_house: '0',
    disable_login: '1'
  };
  private userId = 0;

  constructor(
    private modalController: ModalController,
    private ownerservice: OwnerService,
    private navParams: NavParams,
    private alertController: AlertController
  ) { }
  private accesstoken;

  ngOnInit() {
    this.accesstoken = this.navParams.get('accesstoken');
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + this.accesstoken });
    this.ownerservice.headers = headers;
  }

  compute(form: any) {
    this.owner = {
      name: form.value.name,
      surname: form.value.surname,
      email: form.value.email,
      password: uuid.v4(),
      in_house: '0',
      disable_login: '1'
    };

    const dataUser = {
      name: form.value.name,
      surname: form.value.surname,
      password: uuid.v4(),
      in_house: '0',
      disable_login: '1'
    };

    if (form.value.email !== '') {
      dataUser['email'] = form.value.email;
    }

    const ownerEntity = this.ownerservice.createEntity(OwnerModel, dataUser);
    this.ownerservice.addEntity(ownerEntity).subscribe((resp) => {
      this.userId = resp.data.id;
      this.dismiss();
    }, (err) => {
      //  const e = 'An unexpected error occurred.';
      //  this.presentErrors([e]);

      let errs = [];
      err.errors.forEach(error => {
        errs.push(error.detail);
      });
      this.presentErrors(errs);

    });
  }

  async dismiss() {
    await this.modalController.dismiss({
      userId: this.userId,
      owner: this.owner
    });
  }

  async presentErrors(errors) {
    let e = '<ul class="errors">';
    errors.forEach(err => {
      e = e + '<li>' + err + '</li>';
    });
    e = e + '</ul>';
    const alert = await this.alertController.create({
      header: 'Form error',
      message: e,
      buttons: [{
        text: 'OK',
        handler: () => {
          alert.dismiss();
        }
      }
      ]
    });
    await alert.present();
  }
}
